import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Past from '../views/Past.vue'
import Upcoming from '../views/Upcoming.vue'
import Insights from '../views/Insights.vue'
//import Member from '../views/Member.vue'
import Mailinglist from '../views/Mailinglist.vue'
import About from '../views/About.vue'

const routes = [
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/past',
    name: 'past',
    component: Past
  },
  {
    path: '/upcoming',
    name: 'upcoming',
    component: Upcoming
  },
 {
  path: '/insights',
 name: 'insights',
component: Insights
},
 // {
  //  path: '/member',
   // name: 'member',
   // component: Member
 // },
  {
    path: '/mailinglist',
    name: 'mailinglist',
    component: Mailinglist
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router