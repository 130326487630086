<template>
  <div class="mainArea">
    <div class="contentCenter">
      <div class="video-container" v-for="(video, index) in videos" :key="index">
        <div class="video-wrapper">
          <img
            v-if="playingIndex !== index"
            :src="video.poster"
            :alt="video.title"
            class="video-poster"
            @click="playVideo(index)"
          />
          <button
            v-if="playingIndex !== index"
            class="play-button"
            @click="playVideo(index)"
          >
            ▶
          </button>
          <video
            ref="videoPlayers"
            v-show="playingIndex === index"
            controls
            :src="video.src"
            class="video-player"
            @ended="stopVideo"
            disablepictureinpicture
            controlslist="nodownload noplaybackrate nostats"
            preload="none"
          ></video>
        </div>
        <p>{{ video.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VideoPlayer',
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        videos: [
          { src: 'https://fomoartspace.ch/videos/friendly_space_web.mp4', poster: 'https://fomoartspace.ch/videos/friendly_space_web.jpg', title: 'Friendly Space - Group exhibition' },
          { src: 'https://fomoartspace.ch/videos/a_a_artist_talk.mp4', poster: 'https://fomoartspace.ch/videos/a_a_artist_talk.jpg', title: 'Aline Witschi & Angelos Merges - The Radiance of Earthen Bonds' },
          { src: 'https://fomoartspace.ch/videos/m_j_artist_talk.mp4', poster: 'https://fomoartspace.ch/videos/m_j_artist_talk.jpg', title: 'Maya Lama & Juris Strangots - Small Talk' },
        ],
        playingIndex: null,
      };
    },
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      playVideo(index) {
        if (this.playingIndex !== null && this.$refs.videoPlayers[this.playingIndex]) {
          this.$refs.videoPlayers[this.playingIndex].pause();
          this.$refs.videoPlayers[this.playingIndex].currentTime = 0;
        }
        this.playingIndex = index;
        this.$nextTick(() => {
          this.$refs.videoPlayers[index].play();
        });
      },
      stopVideo() {
        this.playingIndex = null;
      },
    },
  };
</script>

<style lang="scss">


.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 640px;
}

.video-poster {
  width: 100%;
  cursor: pointer;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  width: 100%;
}
</style>
