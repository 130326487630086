<template>
  <div class="mainArea">
    <div class="contentCenter">
      <h1>REBOOT 1.0</h1>
      <h2>The internationally focused exhibition series runs its first edition.</h2>
      <br>
      <h2>Featuring: Featuring: Nadia Hauri, Leif Kauz, John Miličić, Filip Radić, Mara Röllin, Marcel Schwittlick</h2>
      <br>
      <h4>Opening: Fr. 17.01.25, 18:00</h4>
      <br>
      <h2>18.01.25 - 07.02.25</h2>
      <h2>Visits by appointment: mail@fomoartspace.ch</h2>
      <br>
      <img src="../assets/img/upcoming_13.jpg" alt="" class="contentImgBig">
      <br>
      <h2>Reboot, a new exhibition series with an international focus, is now launching as a continuation of previous projects associated with the International Student Biennial.</h2>
      <br>
      <h2>The exhibition will feature new works by artists from earlier projects alongside contributions from local artists.</h2>
      <br>
      <h2>Emphasis is placed on the personal development of young artists and fostering discourse on an international level.</h2>
      <br>
      <a href="https://www.instagram.com/haurinadia" target="_blank"><h5 class="underline">Nadia Hauri on Instagram</h5></a>
      <a href="https://www.instagram.com/einfachesinnlosigkeit" target="_blank"><h5 class="underline">Leif Kauz on Instagram</h5></a>
      <a href="https://www.instagram.com/johnmilicic" target="_blank"><h5 class="underline">John Miličić on Instagram</h5></a>
      <a href="https://www.instagram.com/mara.roellin" target="_blank"><h5 class="underline">Mara Röllin on Instagram</h5></a>
      <a href="https://www.instagram.com/schwittlick" target="_blank"><h5 class="underline">Marcel Schwittlick on Instagram</h5></a>
    </div>
  </div>
</template>
<script>
  export default { 
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>