<template>
  <div>

    <!-- ########################## NAVIGATION MOBILE ##########################  -->
    <Transition name="fade">
      <div class="navigationMobile" v-if="navMobile">
        <div class="headerMobile">
         <div class="logoMobile">
              <img src="./assets/img/logo.png" />
            </div>
            <i class="fa-solid fa-xmark"  @click="navMobile = false"></i>
        </div>
        <div class="navigationMobileArea">
            <nav>
                <ul>
                  <li v-for="(item, x) in menu" :key='x' ><router-link :to="item.location" @click="navMobile = false" :class=" {active: $route.path === item.location}">{{item.title}}</router-link></li>
                </ul>
            </nav>
        </div> 
    </div>  
  </Transition>
  <!-- ########################## END NAVIGATION MOBILE ##########################  -->


    <div :class=" {mainContainer: $route.name === 'home', mainContainerBlank: $route.name != 'home' } ">
    <div class="headerDesktop">
        <div class="logoDesktop">
          <router-link to="/"><img src="./assets/img/logo.png" /></router-link>
        </div>
        <nav class="navigationDesktop">
            <ul>
                <li v-for="(item, x) in menu" :key='x' :class=" {active: $route.path === item.location}"><router-link :to="item.location">{{item.title}}</router-link></li>
            </ul>
        </nav>
    </div> 
    <div class="headerMobile">
        <div class="logoMobile">
          <router-link to="/"><img src="./assets/img/logo.png" /></router-link>
        </div>
        <i class="fa-solid fa-bars"  @click="navMobile = true"></i>
    </div>
    
      <router-view/>
    
    <div class="footerDesktop">
        <a href="http://www.instagram.com/fomoartspace"><i class="fa-brands fa-instagram"></i></a>
        <p>Enzianweg 4 - 8048 Zürich <br />Sa. 14:00 - 18:00 or mail@fomoartspace.ch</p>
          <div class="row">
            <div class="col1"><img src="./assets/img/partner_logos_1.png"></div>
            <div class="col2"><img src="./assets/img/partner_logos_2.png"></div>
          </div>
    </div>  
    <div class="footerMobile">
        <a href="http://www.instagram.com/fomoartspace"><i class="fa-brands fa-instagram"></i></a>
        <p>Enzianweg 4 - 8048 Zürich <br />Sa. 14:00 - 18:00 or mail@fomoartspace.ch</p>
        <div class="row">
            <div class="col1"><img src="./assets/img/partner_logos_1.png"></div>
            <div class="col2"><img src="./assets/img/partner_logos_2.png"></div>
          </div>
    </div>  
    </div>
  </div>
</template>
<script>
export default {
  name: '${COMPONENT_NAME}',
  components: {
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      rnm:this.$route.name,
      navMobile:false,
      menu:[
        {
          title:"About",
          location:"/about"
        },
         {
          title:"Upcoming",
          location:"/upcoming"
        },
        {
          title:"Past",
          location:"/past"
        },
        {
          title:"Insights",
          location:"/insights"
        },
        {
          title:"Mailinglist",
          location:"/mailinglist"
        }//,
        //{
          //title:"Support",
          //location:"/support"
        //} 
        
      ],
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    routerName(){
       return this.$route.name
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
   created() {
    
   },
  // beforeMount() {},
  // render(h) { return h(); },
   async mounted() {


   },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {},
};
</script>
<style lang="scss">
@import 'scss/main.scss';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
  
</style>